import React, { useState, useEffect } from "react";
import NewNftCard from "./common/newNftCard";
import { Carousel } from "react-responsive-carousel";
import Heading from "./common/heading";
import { getSection5 } from "../services/section5";

function Section5(props) {
  const [section5Data, setSection5] = useState([]);
  useEffect(() => {
    const result = getSection5();
    // console.log("dawb", result);
    setSection5(result);
  });
  return (
    <div className="row" style={{ marginBottom: "22px" }}>
      <Heading heading={"Newest NFTs"} />
      {Object.entries(section5Data).map((item, i) => (
        <div key={i} className="col-md-4">
          <Carousel
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
            stopOnHover={true}
          >
            {item[1].map((innerCard) => (
              <NewNftCard key={innerCard.id} obj={innerCard} />
            ))}
          </Carousel>
        </div>
      ))}
    </div>
  );
}

export default Section5;
