import React from "react";
import Image from "./image";
import { NavLink, Link } from "react-router-dom";
function TopBrandCard(props) {
  const { id, background_img, profile_img } = props.obj;
  const { item, view, floor_price, volume } = props.obj.table_data;
  return (
    <Link to="/details" key={id}>
      <div className="card">
        <Image
          classT={"image1"}
          path={background_img}
          //   path={"../assets/images/listing-item-01.jpg"}
        />
        <Image
          classT={"image2"}
          path={profile_img}
          //   path={"../assets/images/listing-item-01.jpg"}
        />

        <div className="containera">
          <h4>
            <b>Sephora</b>
          </h4>
          <p>Beauty</p>
          <table id="customers" style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td>
                  Item <br /> {item}
                </td>
                <td>
                  Views <br /> {view}
                </td>
                <td>
                  Floor Price <br /> {floor_price}
                </td>
                <td>
                  7D Volume <br /> {volume}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Link>
  );
}

export default TopBrandCard;
