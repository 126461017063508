import React from "react";

function Heading(props) {
  const { heading } = props;
  return (
    <div className="col-md-12">
      <h3 className="headline margin-top-40">
        <strong className="headline-with-separator">{heading}</strong>
      </h3>
    </div>
  );
}

export default Heading;
