import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import TopCard from "./allnfts/topCrad";
import TopFilter from "./allnfts/topFilter";
import SideFilter from "./allnfts/sideFilter";
import List from "./allnfts/list";
import Pagination from "./allnfts/pagination";
import Grid from "./allnfts/grid";
function AllNfts(props) {
  return (
    <React.Fragment>
      <div className="container">
        <TopCard />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-9 col-md-8 padding-right-30">
            <TopFilter />
            <Routes>
              <Route path="/list" element={<List />} />
              <Route path="/grid" element={<Grid />} />
            </Routes>
            <div className="clearfix"></div>
            <Pagination />
          </div>
          <SideFilter />
        </div>
      </div>
    </React.Fragment>
  );
}

export default AllNfts;
