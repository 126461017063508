import React from "react";

function Footer(props) {
  return (
    <div id="footer" className="sticky-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <img
              style={{ maxHeight: "77px", width: "57%" }}
              className="footer-logo"
              src="../assets/images/logo.png"
              alt=""
            />
            <br />
            <br />
            <a
              href="https://www.facebook.com/cbvoucherz/"
              className="facebook-link mystyle"
              target="_blank"
            >
              <img
                className="footer-logo footerlogmargin "
                src="../assets/images/fb-icon.png"
                alt=""
              />
            </a>
            <a
              href="https://www.instagram.com/cbvoucher/"
              className="instagram-link"
              target="_blank"
            >
              <img
                className="footer-logo footerlogmargin"
                src="../assets/images/instagram-icon.png"
                alt=""
              />
            </a>
            <a
              href="https://www.twitter.com/cbvoucher/"
              className="twitter-link "
              target="_blank"
            >
              <img
                className="footer-logo footerlogmargin"
                src="../assets/images/twitter-icon.png"
                alt=""
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCtffdQz8jOMYg9TMaVC6EBQ"
              className="youtube-link"
              target="_blank"
            >
              <img
                className="footer-logo footerlogmargin"
                src="../assets/images/youtube-icon.png"
                alt=""
              />
            </a>
            <a
              href="https://discord.gg/QyPRAGt5Rr"
              className="discord-link"
              target="_blank"
            >
              <img
                className="footer-logo footerlogmargin"
                src="../assets/images/discord-icon.png"
                alt=""
              />
            </a>
            <a
              href="https://t.me/cbvoucher"
              className="telegram-link"
              target="_blank"
            >
              <img
                className="footer-logo footerlogmargin"
                src="../assets/images/telegram-icon.png"
                alt=""
              />
            </a>
            <a
              href="https://www.pinterest.com/cbvoucher/"
              className="pinterest-link"
              target="_blank"
            >
              <img
                className="footer-logo footerlogmargin"
                src="../assets/images/pinterest-icon.png"
                alt=""
              />
            </a>

            <br />
            <br />
            <p style={{ fontSize: "14px" }}>
              The world's first and only Gamified NFT Marketplace. VEUGA Is a
              new Visual Advertising Platform VAP that uses Game Mechanics for
              Brand Engagement.
            </p>
          </div>

          <div className="col-md-4 col-sm-6 ">
            <h4>Helpful Links</h4>
            <ul className="footer-links">
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  <b>Resources</b>
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  Help
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  Blog
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  Newsletter
                </a>
              </li>
            </ul>
            <ul className="footer-links">
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  <b>My Account</b>
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  Profile
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  My Brands
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  Settings
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  Activity
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 col-sm-6 ">
            <h4 style={{ color: "#fff" }}>Helpful Links</h4>
            <ul className="footer-links">
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  <b>Marketplace</b>
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  Profile
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  All NFTs
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  Art
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  Collectables{" "}
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  Utility
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  Sports
                </a>
              </li>
            </ul>

            <ul className="footer-links">
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  <b>Company</b>
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  About
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  Mission and Vision
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  Why VEUGA
                </a>
              </li>
              <li>
                <a href="https://cbvoucher.com/" target="_blank">
                  FAQ
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div
              className="copyrights"
              style={{ textAlign: "left", fontSize: "14px" }}
            >
              <span>© Copyright 2022, VEUGA - All Rights Reserved.</span>
              <span style={{ float: "right", fontSize: "14px" }}>
                Privacy | Terms & Condition
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
