import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import Heading from "./common/heading";
import TopSellerCard from "./common/topSellerCard";
function Section4(props) {
  return (
    <div className="row">
      <Heading heading={"Top Sellers"} />
      <div className="col-md-4">
        <Carousel
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          stopOnHover={true}
        >
          <TopSellerCard />
          <TopSellerCard />
        </Carousel>
        <Carousel
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          stopOnHover={true}
        >
          <TopSellerCard />
          <TopSellerCard />
        </Carousel>
      </div>
      <div className="col-md-4">
        <Carousel
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          stopOnHover={true}
        >
          <TopSellerCard />
        </Carousel>
        <Carousel
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          stopOnHover={true}
        >
          <TopSellerCard />
        </Carousel>
      </div>
      <div className="col-md-4">
        <Carousel
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          stopOnHover={true}
        >
          <TopSellerCard />
        </Carousel>
        <Carousel
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          stopOnHover={true}
        >
          <TopSellerCard />
        </Carousel>
      </div>
    </div>
  );
}

export default Section4;
