import React from "react";
import Button from "../common/button";
function Card3(props) {
  return (
    <div className="row">
      <div className="col-lg-12 col-md-12">
        <div className="dashboard-list-box with-icons margin-top-20">
          <h4>Comments</h4>
          <div id="add-listing">
            <div className="add-listing-section">
              <div className="row with-forms">
                <div className="col-md-12 " style={{ marginTop: "15px" }}>
                  <input
                    className="search-field"
                    type="text"
                    placeholder="Leave A comment here"
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <Button
                        classT={"button add-pricing-submenu "}
                        label={"Add Comment"}
                      />
                    </div>
                    <div className="col-md-6" style={{ marginTop: "24px" }}>
                      Or directly message the seller
                      <li style={{ color: "blue" }} className="fa fa-envelope">
                        &nbsp;&nbsp;Message
                      </li>
                    </div>
                  </div>

                  <hr />
                  <p>Showing 0 - 0 out of 0</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card3;
