import React from "react";
import Button from "../common/from";
import Image from "../common/image";
function AboutSeller(props) {
  return (
    <div className="col-lg-6 col-md-6">
      <div className="dashboard-list-box with-icons margin-top-20">
        <h4>About Seller</h4>
        <ul>
          <div className="row">
            <div
              className="col-md-12"
              style={{ marginTop: "10px", marginBottom: "128px" }}
            >
              <li>
                <Image
                  path={"../assets/images/listing-item-01.jpg"}
                  classT={"image3"}
                />

                <b>
                  coolapeclub1 <br />
                </b>
                <a href="#" style={{ color: "blue" }}>
                  @coolapeclub1
                </a>
                <table
                  id="customers"
                  style={{
                    width: "121%",
                    marginTop: "19px",
                    marginLeft: "-72px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        Item <br /> 1000
                      </td>
                      <td>
                        Views <br /> 2000
                      </td>
                      <td>
                        Floor Price <br /> 30000
                      </td>
                      <td>
                        7D Volume <br /> 40000
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ marginTop: "19px", marginLeft: "-72px" }}>
                  Hi, my name is Amanda. The Cool Ape Club is a collection of
                  unique apes NFTs— unique digital collectibles living on the
                  Ethereum blockchain.
                </p>
                <Button
                  classT={"button add-pricing-list-item"}
                  label={"View Profile"}
                />
              </li>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default AboutSeller;
