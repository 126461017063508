const section5 = {
  card: [
    {
      id: "1",
      name: "Sephoraa",
      owner_name: "Sephoraa",
      views: "109",
      price: "1",
      text: "Based on the war between Russia and Ukraine, this nft is a drawn representation of a nuclear explosion that would destroy the earth.",
      background_img: "../assets/images/listing-item-01.jpg",
      profile_img: "../assets/images/listing-item-01.jpg",
    },
    {
      id: "2",
      name: "Sephoraa",
      owner_name: "Sephoraa",
      views: "109",
      price: "4",
      text: "Based on the war between Russia and Ukraine, this nft is a drawn representation of a nuclear explosion that would destroy the earth.",
      background_img: "../assets/images/listing-item-01.jpg",
      profile_img: "../assets/images/listing-item-01.jpg",
    },
    {
      id: "3",
      name: "Sephoraa",
      owner_name: "Sephoraa",
      views: "109",
      price: "2",
      text: "Based on the war between Russia and Ukraine, this nft is a drawn representation of a nuclear explosion that would destroy the earth.",
      background_img: "../assets/images/listing-item-01.jpg",
      profile_img: "../assets/images/listing-item-01.jpg",
    },
  ],
  card2: [
    {
      id: "4",
      name: "Sephoraa",
      owner_name: "Sephoraa",
      views: "109",
      price: "3",
      text: "Based on the war between Russia and Ukraine, this nft is a drawn representation of a nuclear explosion that would destroy the earth.",
      background_img: "../assets/images/listing-item-01.jpg",
      profile_img: "../assets/images/listing-item-01.jpg",
    },
    {
      id: "5",
      name: "Sephoraa",
      owner_name: "Sephoraa",
      views: "109",
      price: "1",
      text: "Based on the war between Russia and Ukraine, this nft is a drawn representation of a nuclear explosion that would destroy the earth.",
      background_img: "../assets/images/listing-item-01.jpg",
      profile_img: "../assets/images/listing-item-01.jpg",
    },
    {
      id: "6",
      name: "Sephoraa",
      owner_name: "Sephoraa",
      views: "109",
      price: "2",
      text: "Based on the war between Russia and Ukraine, this nft is a drawn representation of a nuclear explosion that would destroy the earth.",
      background_img: "../assets/images/listing-item-01.jpg",
      profile_img: "../assets/images/listing-item-01.jpg",
    },
    {
      id: "7",
      name: "Sephoraa",
      owner_name: "Sephoraaaaaaa",
      views: "109",
      price: "3",
      text: "Based on the war between Russia and Ukraine, this nft is a drawn representation of a nuclear explosion that would destroy the earth.",
      background_img: "../assets/images/listing-item-01.jpg",
      profile_img: "../assets/images/listing-item-01.jpg",
    },
  ],
};

export function getSection5() {
  return section5;
}
