import React from "react";
import Button from "../common/from";
function DescriptionCard(props) {
  return (
    <div className="col-lg-6 col-md-6">
      <div className="dashboard-list-box with-icons margin-top-20">
        <h4>Description</h4>
        <ul>
          <li style={{ paddingLeft: "17px" }}>
            <p>
              The Cool Ape Club is a collection of unique apes NFTs— unique
              digital collectibles living on the Ethereum blockchain.
              <br />
              By purchasing this item you will be whitelisted for my future
              projects.
            </p>
          </li>

          <li style={{ paddingLeft: "17px" }}>
            <div className="row">
              <div className="col-md-6">
                <p>Copyright Transferable</p>
                <p>Downloadable File</p>
                <p>Resellable NFT</p>
              </div>
              <div className="col-md-6">
                <p style={{ color: "#0fc372" }}>
                  <i className="fa fa-check-circle"></i>Available
                </p>
                <p style={{ color: "#0fc372" }}>
                  <i className="fa fa-check-circle"></i>Available
                </p>
                <p style={{ color: "#0fc372" }}>
                  <i className="fa fa-check-circle"></i>Available
                </p>
              </div>
            </div>
          </li>
          <li style={{ paddingLeft: "17px" }}>
            <Button
              classT={"button add-pricing-list-item"}
              label={"collectbies"}
            />
            <Button classT={"button add-pricing-submenu "} label={"Avatar"} />
            <Button classT={"button add-pricing-submenu "} label={"Ape"} />
            <Button classT={"button add-pricing-submenu "} label={"Bored"} />
            <Button classT={"button add-pricing-submenu "} label={"Art"} />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default DescriptionCard;
