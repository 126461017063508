import React from "react";
import { NavLink, Link } from "react-router-dom";
function TopFilter(props) {
  return (
    <React.Fragment>
      <div className="row margin-bottom-25">
        <div className="col-md-2 col-xs-2">
          <div className="layout-switcher">
            <NavLink to="/all_nfts/grid" className="grid">
              <i className="fa fa-th"></i>
            </NavLink>
            <NavLink to="/all_nfts/list" className="list ">
              <i className="fa fa-align-justify"></i>
            </NavLink>
          </div>
        </div>

        <div className="col-md-10 col-xs-10">
          <div className="fullwidth-filters">
            {/* <div className="panel-dropdown wide float-right">
              <a href="#">Status</a>
              <div className="panel-dropdown-content checkboxes">
                <div className="row">
                  <div className="col-md-6">
                    <input id="check-a" type="checkbox" name="check" />
                    <label htmlFor="check-a">Buy Now</label>

                    <input id="check-b" type="checkbox" name="check" />
                    <label htmlFor="check-b">On Auction</label>
                  </div>

                  <div className="col-md-6">
                    <input id="check-c" type="checkbox" name="check" />
                    <label htmlFor="check-c">New</label>

                    <input id="check-d" type="checkbox" name="check" />
                    <label htmlFor="check-d">Has Offers</label>
                  </div>
                </div>

                <div className="panel-buttons">
                  <button className="panel-cancel">Reset</button>
                  <button className="panel-apply">Apply</button>
                </div>
              </div>
            </div> */}

            <div className="sort-by">
              <div className="sort-by-select">
                <select
                  data-placeholder="Default order"
                  className="chosen-select-no-single"
                >
                  <option>Recently Listed</option>
                  <option>Recently Created</option>
                  <option>Recently Sold</option>
                  <option>Recently Received</option>
                  <option>Ending Soon</option>
                  <option>Price: Low to High</option>
                  <option>Price: High to Low</option>
                  <option>Highest Last Sale</option>
                  <option>Oldest</option>
                </select>
              </div>
            </div>

            <div className="sort-by">
              <div className="sort-by-select">
                <select
                  data-placeholder="Default order"
                  className="chosen-select-no-single"
                >
                  <option>Single items</option>
                  <option>All items</option>
                  <option>Bundles</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TopFilter;
