import React from "react";
import DescriptionCard from "./descriptionCard";
import AboutSeller from "./aboutSeller";
function Card2(props) {
  return (
    <div className="row">
      <DescriptionCard />
      <AboutSeller />
    </div>
  );
}

export default Card2;
