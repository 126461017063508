import React, { Component } from "react";

function Button(props) {
  return (
    <button
      disabled=""
      type="submit"
      className={props.classT}
      style={{ marginRight: "1px" }}
    >
      {props.label}
    </button>
  );
}

export default Button;

// import Joi from "joi-browser";

// class From extends Component {
//   state = {
//     data: {},
//     error: {},
//   };
//   validate = () => {
// const options = {
//   abortEarly: false,
// };
// const { error } = Joi.validate(this.state.data, this.schema, options);
// if (!error) {
//   return null;
// }
// const errors = {};
// for (let item of error.details) {
//   error[item.path[0]] = item.message;
// }
// return error;
//   };
//   validateProperty = ({ name, value }) => {
//     const obj = { [name]: value };
//     const schema = { [name]: this.schema[name] };
//     const { error } = Joi.validate(obj, schema);
//     return error ? error.details[0].message : null;
//   };
//   handleChange = ({ currentTarget: input }) => {
//     const error = { ...this.state.error };
//     const data = { ...this.state.data };
//     const errorMessage = this.validateProperty(input);
//     if (errorMessage) error[input.name] = errorMessage;
//     else delete error[input.name];
//     data[input.name] = input.value;
//     this.setState({ data, error });
//   };
//   handleSubmit = (e) => {
// e.preventDefault();
// const error = this.validate();
// this.setState({ error: error || {} });
// if (error) return;
// this.doSubmit();
//   };
//   renderButton = (label, classT) => {
//     return (
//       <button disabled={this.validate()} type="submit" className={classT}>
//         {label}
//       </button>
//     );
//   };
// }

// export default From;
