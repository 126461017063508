const topSlider = [
  {
    id: "1",
    background_img: "../../assets/images/unnamed.gif",
    title: {
      title_icon: "../../assets/images/unnamed (2).png",
      title_name: "Ape Yacht Club",
      title_name_red: "By BoredApeYachtClub",
    },
    item: " 10a.0K",
    owners: "6.4K",
    floor_price: "109.5K",
    volume: "1005.3K",
    image1: "../../assets/images/unnamed (1).gif",
    image2: "../../assets/images/unnamed (1).png",
    image3: "../../assets/images/unnamed (2).gif",
    image4: "../../assets/images/unnamed.png",
  },
  {
    id: "2",
    background_img: "../../assets/images/unnamed.jpg",
    title: {
      title_icon: "../../assets/images/unnamed (2).png",
      title_name: "Ape Yacht Club",
      title_name_red: "By BoredApeYachtClub",
    },
    item: " 10.0K",
    owners: "6.4K",
    floor_price: "109.5K",
    volume: "1005.3K",
    image1: "../../assets/images/unnamed (3).png",
    image2: "../../assets/images/unnamed (4).png",
    image3: "../../assets/images/unnamed (5).png",
    image4: "../../assets/images/unnamed (5).png",
  },
];

export function getTopSlider() {
  return topSlider;
}
