import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "../common/image";
import Slider from "react-slick";
import { getTopSlider } from "../../services/allnft";
function TopCard(props) {
  const [topSliderData, setTopSlider] = useState([]);
  useEffect(() => {
    const result = getTopSlider();
    // console.log("dawb", result);
    setTopSlider(result);
  });
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <React.Fragment>
      <h2> </h2>
      <Slider {...settings}>
        {topSliderData.map((item) => (
          <div key={item.id}>
            <div
              key={item.id}
              className="slickcss"
              style={{ backgroundImage: `url(${item.background_img})` }}
            >
              <div className="">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h3>
                        <Image path={item.title.title_icon} classT={"inline"} />
                        {item.title.title_name}
                        <a href="#">{item.title.title_name_red}</a>
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <h3> {item.item} </h3>
                      <h3>items</h3>
                      <h3 style={{ marginTop: "20px" }}>
                        <Image path={item.image1} classT={"square"} />
                      </h3>
                    </div>
                    <div className="col-md-3">
                      <h3> {item.owners} </h3>
                      <h3>Owners </h3>
                      <h3 style={{ marginTop: "20px" }}>
                        <Image path={item.image2} classT={"square"} />
                      </h3>
                    </div>
                    <div className="col-md-3">
                      <h3>{item.floor_price} </h3>
                      <h3>Floor price </h3>
                      <h3 style={{ marginTop: "20px" }}>
                        <Image path={item.image3} classT={"square"} />
                      </h3>
                    </div>
                    <div className="col-md-3">
                      <h3>{item.volume} </h3>
                      <h3>volume </h3>
                      <div className="container2" style={{ marginTop: "20px" }}>
                        <div className="centered">
                          <Image path={item.image4} classT={"square"} />
                          <b>+5 more</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </React.Fragment>
  );
}

export default TopCard;
