import React from "react";
import { NavLink, Link } from "react-router-dom";
import Button from "./common/from";
import pageLogo from "../assets/images/logo.png";
import userPic from "../assets/images/amin-avatar.jpeg";

function Header(props) {
  return (
    <header id="header-container" className="no-shadow">
      <div id="header">
        <div className="container" style={{ width: "1350px" }}>
          <div className="left-side " style={{ width: "81%" }}>
            <div id="logo" style={{ marginRight: "10px" }}>
              <Link to="/">
                <img src={pageLogo} alt="" style={{ maxHeight: "65px" }} />
              </Link>
            </div>
            <nav id="navigation" className="style-1 paddding">
              <ul id="responsive">
                <li>
                  <div
                    className="main-search-input"
                    style={{ width: "93%", marginTop: "-14px" }}
                  >
                    <div
                      className="main-search-input-item"
                      style={{ borderRight: "0px" }}
                    >
                      <input type="text" placeholder="Search Brands as NFT" />
                    </div>
                  </div>
                </li>
                <li>
                  <a className="current" href="#">
                    Discover
                  </a>
                  <ul>
                    <li>
                      <a href="#">Browse Collection</a>
                    </li>
                    <li>
                      <Link to="/all_nfts/list" key={"1"}>
                        All NFTs
                      </Link>
                    </li>
                    <li>
                      <a href="#">Clothing</a>
                    </li>
                    <li>
                      <a href="#">Technology</a>
                    </li>
                    <li>
                      <a href="#">Utility</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Activity</a>
                </li>
                <li>
                  <a href="#">Resources</a>
                </li>
                <li>
                  <a href="#">Create</a>
                </li>
                <li>
                  <Button label={"Connect Wallet"} classT={"button"} />
                </li>
              </ul>
            </nav>
          </div>

          <div className="right-side paddding" style={{ width: "19%" }}>
            <div className="header-widget">
              <div className="user-menu">
                <div className="user-name">
                  <span>
                    <img src={userPic} alt="" />
                  </span>
                  Hi, Amin!
                </div>
                <ul>
                  <li>
                    <a href="dashboard.html">
                      <i className="sl sl-icon-settings"></i> Dashboard
                    </a>
                  </li>
                  <li>
                    <a href="dashboard-messages.html">
                      <i className="sl sl-icon-envelope-open"></i> Messages
                    </a>
                  </li>
                  <li>
                    <a href="dashboard-bookings.html">
                      <i className="fa fa-calendar-check-o"></i> Orders
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="sl sl-icon-power"></i> Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
